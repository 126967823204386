export const dateInputGroupStyle = {
    maxWidth: '18ch',
    '@media (max-width: 768px)': {
        maxWidth: '5ch',
    },
    '& input': {
        '@media (max-width: 768px)': {
            color: 'transparent',
        },
    },

    '& div.chakra-input__right-element': {
        'media (max-width: 768px)': {
            marginLeft: 0,
        },
    },
};
export const disabledSelectedDateStyle = {
    'button[aria-pressed="true"]': {
        background: 'brand.400',
    },
};

export const getDayofMonthBtnProps = (darkMode) => ({
    defaultBtnProps: {
        borderRadius: '50%',
        width: '2rem',
        _hover: {
            background: 'brand.400',
        },
        _active: {
            color: 'brand.500',
            background: 'brand.100',
        },
        color: darkMode ? 'dark.textDark' : 'black',
    },
    selectedBtnProps: {
        background: 'brand.400',
        borderColor: 'blue.300',

        _hover: {
            background: 'brand.300',
        },
    },
});
